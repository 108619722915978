<template>
  <div class="v-tabs">
    <div class="v-tabs__row">
      <div
          class="v-tabs__tab"
          :class="{'v-tabs__tab--active': tab.component === selectedTab}"
          v-for="tab in tabs"
          :key="tab.component"
          @click="onSelect(tab)"
      >
        {{ tab.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VTabs',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    selectedTab: {
      type: String,
      required: true,
    }
  },
  methods: {
    onSelect(tab) {
      this.$emit('click', tab);
    }
  }
}
</script>

<style lang="scss" scoped>
.v-tabs {
  display: flex;
  justify-content: center;
  align-items: center;

  &__row {
    display: flex;
    column-gap: 47px;
    padding-bottom: 16px;
    border-bottom: 1px solid #DDE4EB;
  }

  &__tab {
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      bottom: -17px;
      left: 0;
      width: 100%;
      height: 2px;
      display: none;
      background: var(--orange-main);
    }

    &--active {
      color: var(--orange-main);

      &::before {
        display: block;
      }
    }
  }
}
</style>