<template>
  <div>
    <h2 class="title-page">Settings</h2>
    <div class="mb-5">
      <VTabs :tabs="tabs" :selected-tab="_selectedTab" @click="handleTabClick"/>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import VTabs from '@/js/components/common/VTabs';

export default {
  name: "Settings",
  components: {VTabs},
  data() {
    return {
      tabs: [
        {title: 'Profile', component: 'profile'},
        {title: 'Password and Security', component: 'security'},
        {title: 'Global Postback', component: 'postback'},
        {title: 'Notifications', component: 'notifications'}
      ],
    }
  },
  computed: {
    _selectedTab() {
      const splitPath = this.$route.path.split('/');
      const tabKey = splitPath[splitPath.length - 1];
      return tabKey;
    },
  },
  mounted() {
    if (this.$route.path === '/settings') {
      this.$router.push('/settings/profile');
    }
  },
  methods: {
    handleTabClick(tab) {
      this.$router.push(`/settings/${tab.component}`);
    }
  }
}
</script>

<style scoped>

</style>